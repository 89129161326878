var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tutti-dialog", {
    ref: "dialog",
    attrs: { buttons: _vm.buttons, "max-width": _vm.maxWidth || 400 },
    scopedSlots: _vm._u(
      [
        {
          key: "title",
          fn: function () {
            return [
              _vm._t("title", function () {
                return [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } }),
                ]
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "body",
          fn: function () {
            return [
              _vm._t("body", function () {
                return [
                  _c("v-card-text", {
                    domProps: { innerHTML: _vm._s(_vm.body) },
                  }),
                ]
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }